import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import authService from "./services/authService";
import { history } from "./_helpers/history";
import AdminProtectedRoute from "./_components/AdminProtectedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Admin Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/resetPassword/ResetPassword")
);
const Page403 = React.lazy(() => import("./views/pages/page403/Page403"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Confirmation = React.lazy(() =>
  import("./views/pages/confirmation/Confirmation")
);
const Verification = React.lazy(() =>
  import("./views/pages/verification/Verification")
);

export function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = authService.getCurrentUser();
    setUser({ user });

    const subscribeUser = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: "YOUR_VAPID_PUBLIC_KEY",
        });

        // Send the subscription to your server
        // axios.post('/api/subscribe', { subscription });

        // For demo purposes, you can use a fake endpoint
        fetch("/push-notification", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscription }),
        });
      } catch (error) {
        console.error("Error subscribing to push notifications:", error);
      }
    };

    subscribeUser();
  }, []);

  return (
    <BrowserRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} user={user} />}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route
            exact
            path="/403"
            name="Page 403"
            render={(props) => <Page403 {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/confirmation"
            name="Confirmation"
            render={(props) => <Confirmation {...props} />}
          />
          <Route
            path="/verification/:confirmationEmailToken"
            name="Verify"
            render={(props) => <Verification {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <AdminProtectedRoute
            path="/"
            name="Admin"
            render={(props) => <TheLayout {...props} />}
          />

          <Redirect to="/dashboard" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
